import './App.css';
import { useState, useEffect } from 'react';
import { slide as Menu } from 'react-burger-menu';


function App() {

  const [image, setImage] = useState('img1.jpg');
  const [imgCounter, setImgCounter] = useState(1);
  const [reviewCounter, setReviewCounter] = useState(0);
  const [isOpen, setIsOpen] = useState(false);


  const reviews = 
  [
    {
      author: "przemo bojar",
      starCount: 5,
      desc: "Polecam! Pełen profesjonalizm, dotrzymywanie terminów, szybko i solidnie. Jestesmy bardzo zadowoleni z remontu mieszkania ktory wykonała firma p. Leszka."
    },
    {
      author: "Agnieszka Środowska",
      starCount: 5,
      desc: "W zeszłym roku firma wyremontowała mi całe mieszkanie. Po tym czasie jestem pewna, że wszystko zrobione było z ogromną dokładnością. Bardzo polecam"
    },
    {
      author: "Karolina Szawica",
      starCount: 5,
      desc: "Firma Perfekt to pełen profesjonalizm! Remont odbył się szybko i sprawnie, w miłej atmosferze. Polecam!"
    },
    {
      author: "Marta Oszust",
      starCount: 5,
      desc: "Polecam! Solidna firma :)"
    }
  ]
  
  const changePhoto = (index) => {
    setImage(`img${index}.jpg`);
  }

  const handleBackClick = () => {
    if (imgCounter === 1) {
      setImgCounter(12);
      changePhoto(12);
    } else {
    setImgCounter(imgCounter - 1);
    changePhoto(imgCounter - 1);
    }
  }

  const handleNextClick = () => {
    if (imgCounter === 12) {
      setImgCounter(1);
      changePhoto(1);
    } else {
    setImgCounter(imgCounter + 1);
    changePhoto(imgCounter + 1);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      handleNextClick();
    }, 5000);

    return () => clearInterval(interval);
  }, [imgCounter]);

  const handleHomeClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('main');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }

  const handleOfferClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('offer');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }

  const handleWorkClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('work');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }

  const handleReviewClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('reviews');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - navbarHeight,
        behavior: 'smooth'
      })
    }
  }

  const handleContactClick = () => {
    document.getElementById("menu").style.width = '0vw';
    setIsOpen(false)
    const navbarHeight = document.getElementsByClassName('navbar')[0].clientHeight;
    const element = document.getElementsByClassName('contact');
    if (element) {
      window.scroll({
        top: element[0].offsetTop - (navbarHeight - 30),
        behavior: 'smooth'
      })
    }
  }

  const handleBackReview = () => {
    if (reviewCounter === 0)
    {
      setReviewCounter(2)
    } else {
      setReviewCounter(reviewCounter - 1)
    }
    
  }

  const handleNextReview = () => {
    if (reviewCounter === 2)
    {
      setReviewCounter(0)
    } else {
      setReviewCounter(reviewCounter + 1)
    }
  }

  const handleMenuClick = () => {
    if (isOpen == false) {
      setIsOpen(true)
      document.getElementById("menu").style.width = '80vw';
    }

    if (isOpen == true) {
      setIsOpen(false)
      document.getElementById("menu").style.width = '0vw';
    }
  }

  return (
    <div className="App">

      <div className="navbar">
        <div className="logo"><a href='/' style={{textDecoration: 'none'}}><a href='/' style={{color: '#8FC93A', textDecoration: 'none'}}>PERFEKT</a>-REMONT</a></div>
        <div className="menu">
          <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleHomeClick}>Strona głowna</span>
          <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleOfferClick}>Oferta</span>
          <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleWorkClick}>Realizacja</span>
          <span style={{cursor: 'pointer'}} className='nav-button' onClick={handleReviewClick}>Opinie</span>
        </div>
        <button className='contact-btn' onClick={handleContactClick}>KONTAKT</button>
        <img src={require('./images/menu.png')} style={{cursor: 'pointer'}} className='nav-menu-btn' onClick={handleMenuClick}></img>
        <div className='navbar-list' id="menu">
          <div className="menu-list">
            <img src={require('./images/menu.png')} style={{cursor: 'pointer'}} className='nav-menu-btn-list' onClick={handleMenuClick}></img>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleHomeClick}>Strona głowna</span>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleOfferClick}>Oferta</span>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleWorkClick}>Realizacja</span>
            <span style={{cursor: 'pointer'}} className='nav-button-list' onClick={handleReviewClick}>Opinie</span>
          </div>
          <button className='contact-btn-list' onClick={handleContactClick}>KONTAKT</button>
        </div>
      </div>

      <div className='main'>
        <div className='main-head'>
          <img src={require('./images/worker2.png')} alt="worker" className="main-img"/>
          <span className='main-text'><span style={{color: '#8FC93A'}}>PERFEKT</span>-REMONT</span>
          <img src={require('./images/worker4.png')} alt="worker" className="main-img"/>
        </div>
        <div className='main-desc'>Zakład Usług Remontowo-Budowlanych „Perfekt” Leszek Stępień działa od 1992 roku. Zajmujemy się kompleksowymi remontami mieszkań, domów oraz obiektów użyteczności publicznej.</div>
        <div className='main-button'>
          <img src={require('./images/worker1.png')} alt="worker" className="main-img"/>
          <button className='info-btn' onClick={handleOfferClick}>Dowiedz się więcej</button>
          <img src={require('./images/worker3.png')} alt="worker" className="main-img"/>
        </div>
      </div>

      <div className='offer'>
        <div className='offer-info'>
          <span className='offer-head'>Nasza oferta</span>
          <span>Firma wykonuje remonty generalne, przebudowy, montaże, na które składają się wszelkiego rodzaju prace takie jak:</span>
          <span>1. Kompleksowe prace elektyczne</span>
          <span>2. Kompleksowe prace hydrauliczne</span>
          <span>3. Prace wykończeniowe:</span>
          <ul>
            <li>układanie glazury, terakoty</li>
            <li>układanie podłóg drewnianych, paneli, wykładzin</li>
            <li>wykonywanie gładzi gipsowych, szpachlowanie</li>
            <li>malowanie, tapetowanie</li>
            <li>montaż sufitów podwieszanych w systemie G-K, Thermatex i inne</li>
            <li>montaż drzwi i okien</li>
            <li>wykonywanie tynków tradycyjnych i strukturalnych</li>
            <li>montaż sztukaterii</li>
          </ul>
          <span>Wykonujemy bezpłatną wycenę naszych usług oraz zapewniamy zakup i transport materiałów budowlanych.</span>
          <span>Przy każdym zleceniu oferujemy dobrą atmosferę oraz uśmiech podczas pracy:)</span>
        </div>
        <div className="offer-tiles">
          <div className="offer-1">
            Prace wykończeniowe
            <div className='tile-imgs'>
              <img src={require("./images/img10.jpg")} alt="work-img" className="work-img"/>
              <img src={require("./images/img4.jpg")} alt="work-img" className="work-img"/>
            </div>
          </div>
          <div className="offer-1">
            Prace elektryczne
            <div className='tile-imgs'>
              <img src={require("./images/img1.jpg")} alt="work-img" className="work-img"/>
              <img src={require("./images/img5.jpg")} alt="work-img" className="work-img"/>
            </div>
          </div>
          <div className="offer-2">
            Prace hydrauliczne
            <div className='tile-imgs'>
              <img src={require("./images/img-bathroom.jpg")} alt="work-img" className="work-img-horizontal"/>
            </div>
          </div>
        </div>
      </div>

      <div className="work">
        <div className="work-head">Realizacje</div>
        <div className="work-desc">Oto zdjęcia z wykonanych prac u klientów</div>
        <div className="work-img-slider">
          <button onClick={handleBackClick} className="arrow-btn-left"><img src={require("./images/arrow.png")} alt="arrow" className="arrow-img-left"/></button>
          <img src={require(`./images/${image}`)} alt="work-img-slider" className='work-img-main'/>
          <button onClick={handleNextClick} className="arrow-btn-right"><img src={require("./images/arrow.png")} alt="arrow" className="arrow-img-right"/></button>
        </div>
      </div>

      <div className='reviews'>
        <div className='reviews-head'>Opinie</div>
        <div className='reviews-desc'>Opinie naszych klientów po wykonaniu naszych usług</div>
        <div className="reviews-slider">
          <button onClick={handleBackReview} className='arrow-btn-left'><img src={require("./images/arrow.png")} alt="arrow" className="arrow-img-left"/></button>
            <div className='review-tile'>
              <div className='review-tile-head'>
                <span className='tile-title'>{reviews[reviewCounter].author}</span>
                <span>{reviews[reviewCounter].starCount}/5 <img src={require('./images/star.png')} alt="star" className='star-img'/></span>
              </div>
              <div className='review-tile-desc'>{reviews[reviewCounter].desc}</div>
            </div>
          <button onClick={handleNextReview} className='arrow-btn-right'><img src={require("./images/arrow.png")} alt="arrow" className="arrow-img-right"/></button>
        </div>
      </div>

      <div className='contact'>
        <div className='contact-head'>Kontakt</div>
        <div className='contact-desc'>
          <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.172027363337!2d20.646121577178484!3d50.88352637167813!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471827bbce3fb52b%3A0x8abb7d26398c7ac2!2sKonstantego%20Ildefonsa%20Ga%C5%82czy%C5%84skiego%206%2F61%2C%2025-409%20Kielce!5e0!3m2!1spl!2spl!4v1707153316644!5m2!1spl!2spl" width="600" height="450" style={{border: 0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div className='contact-info'>
            <span>Zakład Usług Remontowo-Budowlanych Perfekt</span>
            <span>Leszek Stępień</span>
            <span style={{color: "#8FC93A", fontSize: "1.5em", fontWeight: "500"}}>606-281-041</span>
            <span>leszek.stepien@poczta.onet.pl</span>
            <span>Kielce 25-409, ul. Gałczyńskiego 6/61</span>
          </div>
        </div>
      </div>

      <div className='footer'>
        <div className='footer-contact'>
          <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>Kontakt</span>
          <span>Perfekt-Remont</span>
          <span>Leszek Stępień</span>
          <span style={{color: "#000000"}}>606-281-041</span>
          <span>leszek.stepien@poczta.onet.pl</span>
          <span>Kielce, ul. Gałczyńskiego 6/61 25-409</span>
        </div>
        <div className='footer-menu'>
          <span style={{fontWeight: 'bold', fontSize: '1.5em'}}>Menu</span>
          <div className='footer-menu-divider'>
            <div className='footer-menu-div1'>
              <span onClick={handleHomeClick}>Home</span>
              <span onClick={handleOfferClick}>Oferta</span>
              <span onClick={handleWorkClick}>Realizacje</span>
            </div>
            <div className='footer-menu-div2'>
              <span onClick={handleReviewClick}>Opinie</span>
              <span onClick={handleContactClick}>Kontakt</span>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default App;
